@import "../../../scss/abstracts/variables.scss";

.baner_home_end{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0px 0px;
    overflow: hidden;
}

.part_top{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1%;
    background-color: $color-bkg-second;
    @include respond-to(medium){
        height: 180px;
        flex-direction: column;
        padding: 0px 10px;
    }
}
.part_top p{
    color: $color-main;
    font-size: 1.9em;
    font-weight: $font-semibold;
    @include respond-to(medium){
        font-size: 1.7em;
    }
}
.part_top span {
    width: 100px;
    @include respond-to(medium){
        margin: 10px 0px 0;
    }
}
.part_bottom{
    background-image: url("../../../img/home-bannerEnd-bkg.png");
    background-size: cover;
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond-to(medium){
        height: 300px;
    }
}

.box_img{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include respond-to(medium){
        display: none;
    }
}
.box_img img{
    width: 90%;
    position: relative;
    bottom: 40px;
}
.box_info{
    width: 50%;
    @include respond-to(medium){
        width: 90%;
        text-align: center;
    }
}
.box_info h1{
    color: $color-second;
    font-weight: $font-semibold;
    font-size: 2.7em; 
    @include respond-to(medium){
        font-size: 2.2em;
        line-height: 100%;
    }
}
.box_info p{
    color: $color-txt2;
    font-weight: $font-semibold;
    font-size: 1.8em;
    @include respond-to(medium){
        font-size: 1.8em;
        line-height: 100%;
        margin-top: 15px;
    }
}