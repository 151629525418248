@import "../abstracts/variables.scss";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

body {
    font-family: $font-stack;
}

li{
    list-style: none;
}

a{
    text-decoration: none;
    cursor: pointer;
}

button{
    font-family: var(--font-nunito);
}
img{
    width: 100%;
}