@import "../../../scss/abstracts/variables.scss";

h1{
    color: $color-main;
}

.container-p{
    width: 100%;
    margin: 20px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-p p{
    width: 90%;
    margin: 20px 0px;
    font-size: $size-txt1;
    font-weight: $font-regular;
    color: $color-txt2;
    text-align: center;
    line-height: 150%;
    @include respond-to(medium){
        font-size: $size-txt1-m;
        line-height: 140%;
    }
}