@import "../../../scss/abstracts/variables";

.container-banner-home {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../img/home-banner-bkg.png");
    background-size: cover;
    @include respond-to(medium){
        justify-content: center;
        gap: 4%;
        overflow: hidden;
        height: 400px;
    }
}
.banner-icon{
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include respond-to(medium) {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 23%;
        left: -2%;
    }
}
.banner-icon div {
    width: 42%;
    position: relative;
    top: -200px;
    right: 10%;
    @include respond-to(medium){
        top: 20px;
    }
}
.banner-txt {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond-to(medium){
        width: 43%; 
    }
}
.banner-txt ul{
    width: 100%;
}
.banner-img{
    width: 43%;
    @include respond-to(medium){
        width: 44%; 
    }
}
.banner-img img{
    @include respond-to(medium){
        width: 210%;
        position: relative;
        top: 70px;
        left: -40px;
    }
}
.banner-title{
    width: 100%;
    font-size: 2.5em;
    color: $color-main;
    line-height: 105%;
    padding-right: 33%;
    font-weight: $font-semibold;
    @include respond-to(medium){
        font-size: 1.5em;
        padding-right: 0;
    }
}
.banner-info{
    margin: 10px 0px;
    color: $color-txt;
    font-size: $size-txt1;
    @include respond-to(medium){
        font-size: 1em;
    }
}
.container-btn{
    display: flex;
    justify-content: flex-start;
}