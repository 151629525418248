@import "../../../scss/abstracts/variables.scss";

.container{
    width: 60%;
    margin: auto;
}
.title{
    font-size: 2em;
    color: $color-main;
    margin: 5% 0px 2%;
}

.list_text li{
    margin: 20px 0px;
    font-size: 1.2em;
    color: $color-txt2;
}

.icon{
    margin-top: 20px;
    width: 120px;
}