@import "../../../scss/abstracts/variables.scss";

.container-slider .product article{
    width: 100%;
}
.box{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 10px 20px;
    background: $color-bkg-third;
    padding: 15px;
    border-radius: 7px;
    @include respond-to(medium){
        flex-direction: column;
        height: auto;
    }
}
.box:hover{
    outline: 1px solid #e5e5e5;
    box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.18);
}
/**/
.box .img{
    width: 20%;
    overflow: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond-to(medium){
        width: 100%;
        background-color: #fff;
    }
}
.box .img img{
    @include respond-to(medium){
        width: fit-content;
    }
}
/**/
.box .data{
    width: 50%;
    @include respond-to(medium){
        width: 100%;
        margin: 20px 0px;
    }
}
.box .badge{
    min-width: auto;
    color: $color-txt-white;
    font-size: $size-link;
    background-color: $color-badge;

    padding: 6px 12px;
    width: fit-content;
    border-radius: 5px;

    @include respond-to(medium){
        font-size: $size-link-m;
    }
}
.box .name{
    margin-top: 10px;
    width: 100%;
    color: $color-txt;
    font-size: 1.3em;
    font-weight: $font-semibold;
}
.box .name span{
    color: $color-txt2;
}
.box .stock , .box .rubro , .box .sku{
    color: $color-txt2;
    font-size: 1em;
}
.box .sku{
    margin: 20px 0px 10px;
}
.box .descrip{
    color: $color-txt2;
    font-size: 1.1em;
}
/**/
.box .prices{
    width: 20%;
    @include respond-to(medium){
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
.box .price{
    width: 100%;
    color: $color-txt;
    font-size: 1.4em;
    font-weight: $font-bold;
    padding: 5px 0px;
}
.box .containerBtn{
    margin: 10px 0px;
}