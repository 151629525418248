@keyframes slideDown {
    0% {
        transform: translateY(-1.25rem); /* 20px */
        opacity: 0;
    }
    10% {
        transform: translateY(1.25rem);
        opacity: 1;
    }
    90% {
        transform: translateY(1.25rem);
        opacity: 1;
    }
    100% {
        transform: translateY(1.25rem);
        opacity: 0;
    }
}

.ContenedorAlerta {
    z-index: 100000;
    width: 100%;
    left: 0;
    top: 1.25rem; /* 20px */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideDown 4s ease forwards;

    p {
        color: #fff;
        padding: 1.25rem 2.5rem; /* 20px 40px */
        border-radius: 0.31rem; /* 5px */
        box-shadow: 0px 0px 15px rgba(0,0,0,.1);
        text-align: center;
        background: green;

        // Condiciones para el color de fondo
        &[tipo="error"] {
            background: red;
        }

        &[tipo='exito'] {
            background: green;
        }

        // Estilo por defecto
        &[tipo='default'] {
            background: #000;
        }
    }
    
}

.ContenedorAlerta .exito p{
    background: green !important;
}
.ContenedorAlerta .error{
    background: red;
}
.ContenedorAlerta .default{
    background: #000;
}
