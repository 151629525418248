@import "../../../scss/abstracts/variables.scss";


h1{
    margin-top: 20px;
    color: $color-main;
}
.container{
    width: 97%;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    align-items: flex-start;
    margin: 20px 0px 0px;
}
/**/
.box_map{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box_map iframe{border: none !important;}
/**/
.box_info{
    width: 37%;
    color: $color-txt2;
    padding: 20px 0px 0px;
    @include respond-to(medium){
        width: 90%;
        margin: auto;
    }
}
.box_info span{
    font-size: 1.2em;
    font-weight: $font-semibold;
}
.box_info ul{
}
.box_info ul li{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4%;
    padding: 20px 0px;
    border-bottom: 1px solid $color-separator;
}
.box_info ul li .icon{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.box_info ul li .icon{
    width: 40px;
}
.box_info ul li .txt{
    font-size: 1.2em;
    margin-top: 10px;
}
.box_info ul li:last-child{
    border: none;
}
.box_info ul li:last-child .txt a{
    color: $color-main;
    cursor: pointer;
}
/**/
.box_form{
    width: 62%;
    border-left: 1px solid $color-separator;
    padding: 20px;
    color: $color-txt2;
    @include respond-to(medium){
        width: 100%;
    }
}
.box_form span{
    font-size: 1.2em;
    font-weight: $font-semibold;
}
.box_form form{
    margin-top: 10px;
}
.box_form form div{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0px;
    @include respond-to(medium){
        flex-flow: column;
        align-items: flex-start;
    }
}
.box_form form div label{
    width:25%;
    font-size: 1.2em;
    @include respond-to(medium){
        width: 100%;
    }
}
.box_form form div input , .box_form form div textarea{
    width:50%;
    font-size: 1.2em;
    padding: 10px;
    @include respond-to(medium){
        width: 100%;
    }
}
.box_form form div textarea{
    height: 150px;
    width: 75%;
    @include respond-to(medium){
        width: 100%;
    }
}
.box_form form .container_Btn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include respond-to(medium){
        align-items: flex-end;
    }
}