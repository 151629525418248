@import "../../../scss/abstracts/variables.scss";


.box{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include respond-to(medium){
        margin: 0px 20px;
    }
}

.box .name{
    width: 100%;
    color: $color-txt-white;
    font-size: 1.2em;
    font-weight: $font-semibold;
    background-color: $color-main;
    text-align: center;
    padding: 5px 0px;
}