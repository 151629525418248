@import "../../../scss/abstracts/variables.scss";

.nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: $color-bkg;
    @include respond-to(medium) {
        justify-content: center;
    }
}
.nav .logo{ padding: 10px 0px;}
.nav .logo a img{ 
    position: relative;
    z-index: 10000;
    @include respond-to(medium) {
        z-index: 1;
    }
}
.container-section{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    @include respond-to(medium) {
        display: none;
    }
}
.sections {
    display: flex;
    width: fit-content;
    justify-content: space-around;
}
.sections li{
    padding: 20px 20px;
    z-index: 11;
}
.menu-m{
    display: none;
    position: absolute;
    top: 7%;
    left: 5%;
    @include respond-to(medium) {
        display: block;
    }
}