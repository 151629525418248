@import "../../../scss//abstracts/variables";

.container-slider{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-button-next,
.swiper-button-prev {
  color: $color-main !important;
  &:after {
    font-size: 25px !important;
  }
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: -4px !important;
    right: auto;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: -4px !important;
    left: auto;
}