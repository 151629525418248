@import "../../../scss/abstracts/variables";

.btn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.link{
    font-size: $size-link;
    font-weight: $font-semibold;
    color: $color-main;
    letter-spacing: $txt-spacing;
}
.link:hover{
    color: $color-main-hover;
}
.cta{
    min-width: max-content;
    padding: 10px 30px;
    background-color:  $color-main;
    color: $color-txt-white;
    border-radius: $border-radius;
    font-weight: $font-regular;
    font-size: $size-link;
    border: none;
    cursor: pointer;
    @include respond-to(xlarge){
        padding: 10px;
        white-space: nowrap;
    }
    @include respond-to(medium){
        font-size: $size-link-m;
    }
}
.cta:hover{
    background-color: $color-main-hover;
    transition: 0.5s;
}
.back{
    color: $color-txt;
}
.link-footer{
    font-size: $size-link-footer;
    font-weight: $font-regular;
    color: $color-main;
}
.link-footer:hover{
    color: $color-main-hover;
}