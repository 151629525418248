@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');



//////////////////////////*  FONTS  *//////////////////////////////////
$font-stack: "Nunito Sans", sans-serif;

$font-light: 300;
$font-regular: 500;
$font-semibold: 700;
$font-bold: 900;

$txt-spacing: 1px;

$size-link:1.2em;
$size-link-m:1em;

$size-link-footer:1em;

$size-title:3em;
$size-title-m:2em;

$size-txt1: 1.5em;
$size-txt1-m: 1.2em;

//////////////////////////*  COLORS  *//////////////////////////////////
$color-bkg: #fff8f2;
$color-bkg-second: #feedcf;
$color-bkg-third: rgb(239 239 239);

$color-main: #a42921;
$color-main-hover: #707070;

$color-second: #5e4c9c;

$color-badge: #13A52B;

$color-txt: #232323;
$color-txt2: #707070;
$color-txt-white: #fff;
$color-separator: hsla(0,0%,63%,.25);

//////////////////////////*  CTA/BTN  *//////////////////////////////////

$border-radius: 50px;


//////////////////////////*  Mixins  *//////////////////////////////////

$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-xlarge: 1900px;

@mixin respond-to($breakpoint) {
    @if $breakpoint == small {
        @media (max-width: $breakpoint-small) { @content; }
    } @else if $breakpoint == medium {
        @media (max-width: $breakpoint-medium) { @content; }
    } @else if $breakpoint == large {
        @media (max-width: $breakpoint-large) { @content; }
    } @else if $breakpoint == xlarge {
        @media (min-width: $breakpoint-xlarge) { @content; }
    }
}
  