@import "../../../scss/abstracts/variables.scss";

.footer{
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    align-items: flex-start;
    gap: 2%;
    padding: 3% 2%;
    background-color: $color-bkg-second;
    @include respond-to(medium){
        flex-flow: column;
        align-items: flex-start;
        padding: 20px 20px;
    }
}

.box{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @include respond-to(medium){
        width: 100%;
        margin: 10px 0px;
    }
}
.box p{
    font-size: 1.2em;
    color: $color-main;
    font-weight: $font-semibold;
    margin: 0px 0px 20px;
    @include respond-to(medium){
        font-size: 0.9em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.arrow{
    display: none;
    @include respond-to(medium){
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.box ul{
    @include respond-to(medium){
        margin: 0px 0px 30px;
    }
}
.box ul li{
    font-size: 1.2em;
    color: $color-main;
    font-weight: $font-regular;
    margin: 0px 0px 10px;   
}
.box ul li a{
    font-size: 1em;
    color: $color-main;
    font-weight: $font-regular;
    margin: 0px 0px 10px;   
}
.box_redes{
    width: 10%;
    @include respond-to(medium){
        width: 100%;
    }
}
.box_redes p{
    font-size: 1.2em;
    color: $color-main;
    font-weight: $font-semibold;
    margin: 0px 0px 20px;
    @include respond-to(medium){
        display: flex;
    }
}
.box_redes div{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 5%;
}

.box_redes div span svg{
    width: 30px;
    cursor: pointer;
    fill: $color-main;
}
.box_redes div span svg:hover{
    transition: 1s;
    fill: $color-main-hover;
}

.wpp{
    position: fixed;
    bottom: 10px;
    right: 13px;
    z-index: 100;
}
.wpp img{
    width: 70px;
    @include respond-to(medium){
        width: 40px;
    }
}