@import "../../../scss/abstracts/variables.scss";

.title-slider{
    font-size: $size-title;
    margin: 3% 0% 4%;
    padding: 0px 0px;
    @include respond-to(medium){
        font-size: $size-title-m;
        margin: 5% 0% 8%;
        padding: 0px 0px;
    }
}

.title-slider::after {
    content: "";
    width: 30%;
    display: flex;
    justify-content: center;
    border-bottom: 3px solid $color-main;
    margin: auto;
    position: relative;
    top: 10px;
}