@import "../../../scss/abstracts/variables.scss";

.form-login{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0px 20px 0px;
    margin: 4% auto;
}

.form-login input{
    width: 100%;
    margin: 5px 0px;
    border: none;
    border-bottom: 1px solid #ccc;
    text-align: center;
    font-size: 1.5em;
    height: 10vh;
    color: #747474;
}
.form-login .cta{
    margin: 20px 0px;
}

.mood{
    width: 100%;
    padding: 15px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
}
.subcategories_control{
    cursor: pointer;
    margin-left: 20px;
    font-size: 15px;
    padding: 5px 0px;
    color: #838282;
}
.subcategories_control:hover{
    color: #000;
}
.control-container{
    width: 100%;
}
/*CSS de Productos_Control - STAR */
.container-products{
    width: 100%;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
/*CSS de Productos_Control - END */


/*CSS de Result_Control - STAR */
.container-result{
    width: 100%;
    padding: 1% 1%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.btn-create{
    width: 50px;
    height: 52px;
    background-color: $color-main;
    position: absolute;
    top: 11%;
    right: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    border-radius: 50px;
    color: #fff;
    font-weight: $font-semibold;
    cursor: pointer;
}
.btn-create:hover{
    background-color: #000;
    transition: 0.5s;
}
/**/
.box-breadcurn{
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 10px;
}
.box-breadcurn p , .box-breadcurn span{
    font-size: 1em;
    color: $color-txt2;
    margin: 0px 2px;
}
/**/
.box-filter{
    width: 20%;
    height: 600px;
    padding: 0px 10px;
    @include respond-to(medium){
        display: none;
    }
}
.box-filter h1{
    width: 100%;
    padding: 0px 0px 10px;
    border-bottom: 1px solid #c4c4c4;
    font-size: 1.2em;
    font-weight: $font-semibold;
    color: $color-txt;
    margin-bottom: 12px;
}
.box-filter ul li{
    display: flex;
    flex-direction: column;
}
.box-filter ul li a{
    opacity: 0.8;
    width: 100%;
    font-size: 1em;
    padding: 4px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #000;
}
.box-filter ul li a::before{
    font-family: swiper-icons;
    content: "next";
    font-size: 0.7em;
    margin-right: 5px;
}
.box-filter ul li a:hover{
    opacity: 1;
}
/**/
.box-products{
    width: 79%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include respond-to(medium){
        width: 100%; 
    }
}
.sort{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 10px;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    margin: 15px 0px 0px;
}
.products{
    width: 100%;
    padding: 20px 10px;
}
.box-products h1{
    margin-top: 20px;
    color: $color-main;
}
.box-products h1 span{
    color: $color-txt2;
    font-size: 15px;
    font-weight: $font-regular;
    position: relative;
    top: -15px;
    left: 5px;
}
/*CSS de Result_Control - END */

/*CSS de BTN Control - STAR */
.edit , .delete{
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.edit svg{
    width: 30px;
}
.delete svg{
    width: 30px;
}
/*CSS de BTN Control - END */