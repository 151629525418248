@import "../../../scss/abstracts/variables.scss";
.container-modal{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-modal .encabezados {
    margin: 3% 0px 2%;
}
.ReactModal__Content.ReactModal__Content--after-open {
    width: 60%;
    margin: auto;
}
/**/
.btn-update{
    min-width: max-content;
    padding: 10px 30px;
    background-color:  $color-main;
    color: $color-txt-white;
    border-radius: $border-radius;
    font-weight: $font-regular;
    font-size: $size-link;
    border: none;
    cursor: pointer;
    @include respond-to(xlarge){
        padding: 10px;
        white-space: nowrap;
    }
    @include respond-to(medium){
        font-size: $size-link-m;
    }
}
.btn-update:hover{
    background-color: $color-main-hover;
    transition: 0.5s;
}
.container-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-form .form-group{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 10px 0px;
}
.container-form .form-group label{
    font-size: 0.8em;
    color: #555555;
}
.container-form .form-group input , .container-form .form-group textarea{
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    font-size: 0.9em;
}
.container-form .form-group textarea{
    height: 100px;
    font-size: 1.2em;
    padding: 10px 10px;
}
.container-form .form-group .check{
    width: 50px;
}
.btn-close {
    cursor: pointer;
    position: absolute;
    top: 5%;
    right: 5%;
}
.btn-close img{
    width: 40px;
    position: absolute;
    right: 2%;
    top: 1%;
}
.container-form .form-group select{
    width: 100%;
    height: 50px;
    padding: 5px 10px;
    font-size: 0.9em;
}

.container-form .form-group.check input{
    width: 30px;
}

.thumbail{
    min-width: 70px;
    max-width: 70px;
    min-height: 70px;
    max-height: 70px;
    border: 2px solid $color-main;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 7px;
}

.containerImg{
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
}

.containerImg span{
    margin-left: 30px;
    cursor: pointer;
    font-size: 0.9em;
    color: $color-main;
}