@import "../../../scss/abstracts/variables.scss";

.container-slider .product article{
    width: 100%;
}
.box{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 10px;
}
.box:hover{
    outline: 1px solid #e5e5e5;
    box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.18);
}
.box .name{
    width: 100%;
    min-height: 60px;
    color: $color-txt2;
    font-size: 1.1em;
    font-weight: $font-semibold;
    text-align: center;
    padding: 5px 10px;
}

.box .price{
    width: 100%;
    color: $color-txt;
    font-size: 1.4em;
    font-weight: $font-bold;
    text-align: center;
    padding: 5px 0px;
}

.box .badge{
    color: $color-txt2;
    font-size: 0.9em;
}

.box .containerBtn{
    margin: 20px 0px;
}

.box .img{
    padding: 10px;
    max-height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}