@import "../../../scss/abstracts/variables.scss";

.box{
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include respond-to(medium){
        margin: 0px 20px;
    }
}
.box .img{display: flex; overflow: hidden;}
.box .name{
    width: 100%;
    color: $color-main;
    font-size: 1.4em;
    font-weight: $font-semibold;
    text-align: center;
    padding: 5px 0px;
}
.box:hover .img img{
    transition: 1s;
    scale: 1.2;
}
.box:not(:hover) .img img{
    transition: 1s;
    scale: 1;
}
.box:hover .name{
    transition: 1s;
    //font-size: 1.6em;
    scale: 1.4;
}
.box:not(:hover) .name{
    transition: 1s;
    //font-size: 1.4em;
}