@import "../../../scss/abstracts/variables.scss";

.products-expand{
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 10;
    top:12%;
    left: 0;
    background-color: $color-bkg;
    padding: 20px 20px;
    @include respond-to(xlarge) {
        top: 11%;
    }
}

.products-expand ul{ 
    width: 80%;
    margin: auto;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.products-expand ul li{ 
    width: 23%;
    margin: 10px;
    
}
.products-expand a{ 
    width: 23%;
    cursor: pointer; 
}
.category{
    font-size: $size-link;
    font-weight: $font-semibold;
    color: $color-main;
    letter-spacing: $txt-spacing;
    margin-top: 10px;
}
.category:hover{
    color: $color-main-hover;
}
.sub-category{
    margin: 5px 0px;
    font-size: 1em;
    font-weight: $font-regular;
    color: $color-txt;
    letter-spacing: $txt-spacing;
    cursor: pointer;
    @include respond-to(medium) {
        font-size: 0.9em;
    }
}