@import "../../../scss/abstracts/variables.scss";

.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 26px;
  left: 0px;
  top: 5px;
}
  
  .bm-burger-bars {
    background: $color-main;
  }
  
  .bm-burger-bars-hover {
    background: $color-main-hover;
  }
  
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  .bm-cross {
    background: #bdc3c7;
  }
  
  .bm-menu {
    background: $color-bkg-second;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  .bm-item-list {
    color: $color-main;
    padding: 0.8em;
  }
  
  .bm-item {
    display: inline-block;
  }
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
  }
  .bm-menu-wrap {
    top: 0;
    left: 0;
}
.menu-item{
    color: $color-main;
}
.menu-item:hover{
    color: $color-main-hover;
}
.menu-m-links{
  margin-top: 20px;
}
.menu-m-categ li a p{
  color: red;
}