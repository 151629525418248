@import "../../../scss/abstracts/variables.scss";

.container-disclaimer{
    width: 100%;
    background-color: $color-bkg;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
    padding: 0px 20px;
    @include respond-to(medium){
        height: 150px;
        flex-flow: wrap;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        text-align: center;
        margin: 20px 0px;
    }
}
.data-fiscal img{
    height: 70px;
    @include respond-to(medium){
        margin-top: 10px;
    }
    
}